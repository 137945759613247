<div fxFlexFill fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px" class="sub-page">
    <h1 [innerHtml]="page?.title?.rendered"></h1>
    <div *ngIf="!page?.acf?.bio_photo" class="content" [innerHtml]="page?.content?.rendered"></div>
    <div *ngIf="page?.acf?.bio_photo" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="4em" fxLayoutGap.lt-md="2em" style="max-width: 1024px; flex: none;">
        <div fxLayout="column" fxLayoutAlign="start center" fxFlex.gt-sm="50">
            <img class="bio-photo" [src]="page?.acf?.bio_photo">
            <p class="bio-photo-caption" [innerHtml]="page?.acf?.bio_photo_caption"></p>
        </div>
        <div class="content justified" [innerHtml]="page?.content?.rendered" fxFlex.gt-sm></div>
    </div>
    <br>
    <img class="close" (click)="clickClose()" src="assets/img/close.svg">
</div>