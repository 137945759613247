<div fxFlexFill fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px" class="sub-page">
    <h1 [innerHtml]="name"></h1>
    <div class="custom-gallery" fxLayout="row" [ngClass]="{'no-scroll': noScroll}">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100px" fxFlex.lt-sm="70px" (click)="galleryPrev()"
            class="gallery-nav"><img src="assets/img/left-chevron.png"></div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex class="gallery-items" #galleryItemsContainer>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="48px" class="gallery-item video"
                #videoPlayerOverlay *ngIf="!noVideo">
                <div *ngIf="videoHost === 'Vimeo'" class="video-player-container"
                    [style.minHeight.px]="videoPlayerHeight">
                    <iframe class="video-player" *ngIf="sanitizedvideoUrl" [src]="sanitizedvideoUrl" frameborder="0"
                        allowFullScreen></iframe>
                </div>
                <div *ngIf="videoHost === 'Youtube'" class="video-player-container"
                    [style.minHeight.px]="videoPlayerHeight">
                    <iframe [src]="sanitizedvideoUrl" *ngIf="sanitizedvideoUrl" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div *ngFor="let galleryImage of galleryImages" class="gallery-item">
                <img [attr.src]="galleryImage" class="gallery-image">
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100px" fxFlex.lt-sm="70px" (click)="galleryNext()"
            class="gallery-nav"><img src="assets/img/right-chevron.png"></div>
    </div>
    <div class="content justified" [innerHtml]="synopsis"></div>
    <br>
    <img class="close" (click)="clickClose()" src="assets/img/close.svg">
</div>