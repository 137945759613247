import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sub-page',
  templateUrl: './sub-page.component.html',
  styleUrls: ['./sub-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubPageComponent implements OnInit {
  @Input() page: any;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  clickClose() {
    this.close.emit();
  }

  constructor() { }

  ngOnInit(): void {
    console.log(this.page);
  }

}
