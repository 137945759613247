import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { PATH } from 'src/app/path';
import { flatMap, map } from 'rxjs/operators';

@Injectable()
export class WordpressService {

    constructor(private http: HttpClient) { }

    getPage(slug: string): Observable<any> {
        return this.http.get<any[]>(PATH.API_URL + '/pages/?slug=' + slug).pipe(map(pages => {
            if (pages && pages[0]) {
                return pages[0];
            }
        }));
    }

    getPosts(postsPerPage?: number): Observable<any[]> {
        if (!postsPerPage) {
            postsPerPage = 6;
        }
        return this.http.get<any[]>(PATH.API_URL + '/posts?_embed', {
            params: {
                per_page: '' + postsPerPage
            }
        });
    }

    getPostById(postId: number): Observable<any[]> {
        return this.http.get<any[]>(PATH.API_URL + '/posts?&include[]=' + postId).pipe(map(posts => {
            if (posts && posts[0]) {
                return posts[0];
            }
        }));
    }

    getPostsByIds(postIds: number[], postType?: string): Observable<any[]> {
        return this.http.get<any[]>(PATH.API_URL + '/' + (postType ? postType : 'posts') + '?' + postIds.map(id => 'include[]=' + id).join('&'), {
            params: {
                per_page: '100'
            }
        });
    }

    getPostBySlug(slug: string, postType?: string){
        return this.http.get<any[]>(PATH.API_URL + '/' + (postType ? postType : 'posts') + '?slug=' + slug).pipe(map(posts => {
            if (posts && posts[0]) {
                return posts[0];
            }
        }));
    }

    getWork(): Observable<any[]> {
        return this.http.get<any[]>(PATH.API_URL + '/project_type')
            .pipe(
                flatMap(projectTypes => {
                    return forkJoin(
                        projectTypes.filter(type => type.count > 0).map(type => {
                            return this.http.get<any[]>(PATH.API_URL + '/project?project_type=' + type.id, {
                                params: {
                                    per_page: '100'
                                }
                            })
                            .pipe(
                                map(projects => {
                                        return {
                                            name: type.name,
                                            slug: type.slug,
                                            projects: projects
                                        }
                                })
                            );
                        })
                    )
                })
            );
    }
}