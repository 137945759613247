import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LoaderService } from './modules/shared/services/loader.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('fadeInOut', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 0.9 })),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(300, style({ opacity: 0 })))
        ])
    ]
})
export class AppComponent {
    loading: boolean = true;
    showHeader: boolean = true;

    checkHeader(event: NavigationEnd) {
        // if (event.url === '/work') {
        //     this.showHeader = false;
        // } else {
        //     this.showHeader = true;
        // }
    }

    goBack() {
        this.location.back()
    }

    constructor(
        private router: Router,
        private location: Location,
        private LoaderService: LoaderService
    ) {
        this.LoaderService.loading$.subscribe(loading => {
            this.loading = loading;
        });
    }
}
