import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule} from '@angular/flex-layout';
import { WordpressService } from './services/wordpress.service';
import { RouterModule } from '@angular/router';
import { FirstLoadService } from './services/first-load.service';
import { SubPageComponent } from './component/sub-page/sub-page.component';
import { VideoComponent } from './component/video/video.component';
import { LoaderService } from './services/loader.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        FlexLayoutModule
    ],
    
    declarations: [
        SubPageComponent,
        VideoComponent
    ],
    exports: [
        RouterModule,
        FlexLayoutModule,
        SubPageComponent,
        VideoComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                FirstLoadService,
                WordpressService,
                LoaderService
            ]
        };
    }
}
