import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';

@Component({
  selector: 'video-player',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit {
  @ViewChild('videoPlayerOverlay') videoPlayerOverlay: ElementRef<HTMLDivElement>;
  @ViewChildren('.gallery-item') galleryItems: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChild('galleryItemsContainer') galleryItemsContainer: ElementRef<HTMLDivElement>;
  @Input() videoUrl: string;
  @Input() videoHost: string;
  @Input() synopsis: string;
  @Input() name: string;
  @Input() client: string;
  noVideo: boolean;
  sanitizedvideoUrl: SafeResourceUrl;
  videoPlayerHeight: number;
  windowResize$: Observable<Event> = fromEvent(window, 'resize');
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  galleryImages: string[] = [];
  selectedGalleryImage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  galleryOpen: boolean = false;
  noScroll: boolean = true;

  getQueryVariable(url: string, variable: string) {
    try {
      let query = url.split('?')[1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
      }
      return (false);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  refresh() {
    if (this.videoHost) {
      try {
        switch (this.videoHost) {
          case 'Vimeo':
            if (this.videoUrl) {
              let videoId = this.videoUrl.match(/vimeo.com\/.+?(\/|$)/)[0].split('/')[1];
              this.sanitizedvideoUrl = this.santizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + videoId + '?byline=false&color=ffffff&playsinline=false&portrait=false&title=false');
            }
            break;
          case 'Youtube':
            if (this.videoUrl) {
              const v = this.getQueryVariable(this.videoUrl, 'v');
              this.sanitizedvideoUrl = this.santizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + v);
            }
            break;
          default:
            this.noVideo = true;
            break;
        }
        this.calcvideoPlayerHeight();
      } catch (e) {
        console.error(e);
      }
    }
    this.setNoScroll();
  }

  setNoScroll() {
    setTimeout(_ => {
      this.noScroll = !((this.noVideo && this.galleryImages.length > 1) || (!this.noVideo && this.galleryImages.length > 0));
    })
  }

  calcvideoPlayerHeight() {
    setTimeout(_ => {
      if (this.videoPlayerOverlay && this.videoPlayerOverlay.nativeElement) {
        let maxHeight = window.innerHeight - 200;
        let idealHeight = this.videoPlayerOverlay.nativeElement.clientWidth / (16 / 9);
        let height = idealHeight < maxHeight ? idealHeight : maxHeight;
        this.videoPlayerHeight = height;
      }
    })
  }

  clickClose() {
    this.close.emit();
  }

  openGallery(index: number) {
    this.selectedGalleryImage.next(index);
    this.galleryOpen = true;
  }

  closeGallery() {
    this.galleryOpen = false;
  }

  galleryNext() {
    if (!this.noScroll) {
      let max = this.galleryImages.length - 1;
      if (!this.noVideo) {
        max++;
      }
      if (this.selectedGalleryImage.value < max) {
        this.selectedGalleryImage.next(this.selectedGalleryImage.value + 1);
      } else {
        this.selectedGalleryImage.next(0);
      }
    }
  }

  galleryPrev() {
    if (!this.noScroll) {
      let max = this.galleryImages.length - 1;
      if (!this.noVideo) {
        max++;
      }
      if (this.selectedGalleryImage.value > 0) {
        this.selectedGalleryImage.next(this.selectedGalleryImage.value - 1);
      } else {
        this.selectedGalleryImage.next(max);
      }
    }
  }

  constructor(
    private santizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  ngAfterViewInit() {
    setTimeout(_ => {
      this.calcvideoPlayerHeight();
      this.windowResize$
        .subscribe(_ => {
          this.calcvideoPlayerHeight();
        });
    })
    document.querySelectorAll('figure.gallery-item img').forEach((element: HTMLImageElement, index) => {
      this.galleryImages.push(element.src);
      element.style.cursor = 'pointer';
      element.addEventListener('click', _ => this.openGallery(index));
    });
    this.selectedGalleryImage.subscribe(index => {
      if (this.galleryItemsContainer && this.galleryItemsContainer.nativeElement) {
        this.galleryItemsContainer.nativeElement.scrollTo({
          top: 0,
          left: index * this.galleryItemsContainer.nativeElement.clientWidth,
          behavior: 'smooth'
        });
      }
    })
    this.setNoScroll();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeGallery();
  }

}
